import { MemberInvitesApi } from '@wix/social-groups-api';
import { BaseWidgetController } from '../BaseWidgetController';
import { ConsoleLogger } from '../../loggers';
import {
  I$WWrapper,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { MemberInvitesControllerProps } from './MemberInvitesControllerProps';
import { getClubsApiUrl } from '../../utils/baseUrl';
import { MemberInvites } from '../../types/member-invites/MemberInvites';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class MemberInvitesController extends BaseWidgetController<MemberInvitesControllerProps> {
  private api!: MemberInvitesApi;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);

    this.setApi(this.getSiteToken()!);
    this.onUserLogin(async () => {
      this.setApi(this.getSiteToken()!);
    });
  }

  setApi(siteToken: string) {
    const location = this.getLocation();
    this.api = new MemberInvitesApi(
      siteToken,
      getClubsApiUrl(location.baseUrl),
    );
  }

  pageReady($w?: I$WWrapper, wixAPI?: IWixAPI): Promise<void> {
    return Promise.resolve();
  }

  getInitialProps(): Promise<Partial<MemberInvites>> {
    return Promise.resolve({
      loadMemberInvitesLink: this.loadMemberInvitesLink,
    });
  }

  loadMemberInvitesLink = async () => {
    let memberInvitesLink = '';
    try {
      const response = await this.api.getInviteLink();
      memberInvitesLink = response.data.url;
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }

    this.setState({
      memberInvitesLink,
    });
  };
}
