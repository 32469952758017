export enum PluginTypes {
  Giphy = 'wix-draft-plugin-giphy',
  Image = 'wix-draft-plugin-image',
  Poll = 'wix-draft-plugin-poll',
  Gallery = 'wix-draft-plugin-gallery',
  FileUpload = 'wix-draft-plugin-file-upload',
  Video = 'wix-draft-plugin-video',
  ExternalMentions = 'wix-draft-plugin-external-mentions',
  Mention = 'mention',
  LinkPreview = 'wix-draft-plugin-link-preview',
  Emoji = 'wix-draft-plugin-emoji',
  Link = 'LINK',
  VerticalEmbed = 'wix-draft-plugin-vertical-embed',
  Divider = 'wix-draft-plugin-divider',
  Headings = 'wix-rich-content-plugin-headings',
  HTML = 'wix-draft-plugin-html',
  History = 'wix-rich-content-undo-redo',
  LinkButton = 'wix-draft-plugin-link-button',
  ActionButton = 'wix-draft-plugin-action-button',
  TextColor = 'wix-rich-content-text-color',
  TextHighlight = 'wix-rich-content-text-highlight',
  Map = 'wix-draft-plugin-map',
  SoundCloud = 'wix-draft-plugin-sound-cloud',
}
