import { Image, ImageContent } from '../plugins/types/ImageContent';
import { MediaConfig } from '../plugins/types/Media';
import { ImageDescriptor } from '@wix/social-groups-api/dist/src/model/ImageSDK/ImageDescriptor';

export enum ImageConfig {
  FULL_WIDTH = 'FULL_WIDTH',
}

type ConfigByType = {
  [key in keyof typeof ImageConfig]: MediaConfig;
};
const imageConfigByType: ConfigByType = {
  [ImageConfig.FULL_WIDTH]: {
    alignment: 'center',
    size: 'fullWidth',
    showTitle: true,
    showDescription: true,
  },
};

export class ImageContentBuilder {
  private readonly content: ImageContent = {
    config: undefined as any,
    src: undefined as any,
  };

  withSrcFromUrl(url: string) {
    const {
      sourceHeight: height,
      sourceWidth: width,
      relativeUrl: file_name,
    } = ImageDescriptor.fromUrl(url);
    return this.withSrc({
      file_name,
      height,
      id: file_name,
      original_file_name: file_name,
      width,
    } as any);
  }

  withSrc(src: Image) {
    this.content.src = {
      file_name: '',
      height: 0,
      id: '',
      original_file_name: '',
      width: 0,
      ...(src as any),
    };
    return this;
  }

  withConfig(type: ImageConfig) {
    this.content.config = imageConfigByType[type] || {
      alignment: '',
      size: '',
    };
    return this;
  }

  build() {
    return this.content;
  }
}
