import { ErrorOrigin, IErrorEvent } from './IErrorEvent';

export class ControllerError extends Error {
  code: number;
  origin: ErrorOrigin;

  constructor(
    origin: ErrorOrigin,
    response: { statusText: string; status: number },
  ) {
    super(response.statusText);
    this.origin = origin;
    this.code = response.status;
  }

  getErrorEvent(): IErrorEvent {
    return { code: this.code, origin: this.origin };
  }
}
