import Api, { ApiTypes, SocialApiTypes } from '@wix/social-groups-api';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import getBaseUrl from '../../../common/utils/baseUrl';
import {
  BaseControllerContext,
  getGroupUrlSegments,
} from '../../../common/controllers';
import { GroupsStorage } from '../../../common/storage/GroupsStorage';
import { IFeedItem } from '../types/IFeedItem';

export async function getFirstGroup(
  apiClient: any,
): Promise<ApiTypes.v1.GroupResponse> {
  const {
    data: { groups },
  } = await apiClient.getGroupList();
  return groups[groups.length - 1];
}

export const DEFAULT_APPS = {
  [ApiTypes.v1.GroupAppKey.ABOUT_APP]: {
    key: ApiTypes.v1.GroupAppKey.ABOUT_APP,
    installed: true,
  },
  [ApiTypes.v1.GroupAppKey.FEED_APP]: {
    key: ApiTypes.v1.GroupAppKey.FEED_APP,
    installed: true,
  },
  [ApiTypes.v1.GroupAppKey.GALLERY_APP]: {
    key: ApiTypes.v1.GroupAppKey.GALLERY_APP,
    installed: true,
  },
  [ApiTypes.v1.GroupAppKey.MEMBERS_APP]: {
    key: ApiTypes.v1.GroupAppKey.MEMBERS_APP,
    installed: true,
  },
  [ApiTypes.v1.GroupAppKey.EVENTS_APP]: {
    key: ApiTypes.v1.GroupAppKey.EVENTS_APP,
    installed: false,
  },
  [ApiTypes.v1.GroupAppKey.CUSTOM_APP]: {
    key: ApiTypes.v1.GroupAppKey.CUSTOM_APP,
    installed: false,
  },
};

export function getApi(controllerContext: ControllerParams) {
  const {
    appParams: { appDefinitionId },
    wixCodeApi,
  } = controllerContext.controllerConfig;
  const instance = wixCodeApi.site.getAppToken!(appDefinitionId);
  return new Api(
    instance,
    getBaseUrl(wixCodeApi),
    new BaseControllerContext(controllerContext),
  );
}

export async function getGroupFromLocation(
  controllerContext: ControllerParams,
): Promise<ApiTypes.v1.GroupResponse> {
  const {
    wixCodeApi: {
      location,
      site,
      window: { viewMode },
    },
  } = controllerContext.controllerConfig;

  const { groupId } = await getGroupUrlSegments(location, site, viewMode);
  const api = getApi(controllerContext);

  if (groupId) {
    try {
      const storage = GroupsStorage.fromControllerConfig(
        controllerContext.controllerConfig,
      );
      const cachedGroup = storage.getGroup(groupId);
      if (cachedGroup) {
        // one time cache strategy
        storage.removeGroup(groupId);
        return cachedGroup;
      }
      const {
        data: { group },
      } = await api.getGroup(groupId);
      // TODO:test server fallback cache strategy with FT
      return group!;
    } catch (e) {
      console.log(`Failed to get group ${groupId}`);
    }
  }
  try {
    // navigate to group via Groups menu in the editor
    const firstGroup = await getFirstGroup(api);
    if (!firstGroup) {
      const { data } = await api.getDefaultGroup();
      return data.group!;
    }
    return firstGroup;
  } catch (e) {
    console.log(`Failed to get firstGroup`);
  }
  return {};
}

export async function getDefaultPost(
  controllerContext: ControllerParams,
): Promise<IFeedItem> {
  const post: IFeedItem = getEmptyPost();
  try {
    const api = getApi(controllerContext);
    const { data } = await api.getDefaultPostContent();
    post.entity!.body.content = (data as any).defaultPost || data;
    return post;
  } catch (e) {}
  return post;
}

function getEmptyPost(): IFeedItem {
  return {
    attachments: [],
    comments: undefined as any,
    createdAt: undefined as any,
    createdBy: '',
    entity: {
      body: {
        content: undefined as any,
        contentType: SocialApiTypes.ContentType.DRAFTJS,
      },
      topics: [],
    },
    feedItemId: '',
    pin: undefined,
    reactions: {
      total: 0,
      usersReactions: {
        total: 0,
        reactions: [],
      },
      usersReacted: {
        total: 0,
        userIds: [],
      },
    },
    requesterContext: undefined,
  };
}
