import { serializable } from 'serializr';

import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';

export class SocialIdentity implements IFeedTypes.SocialIdentity {
  @serializable
  identityId!: string;

  @serializable
  identityType: any;
}

export class SocialText implements IFeedTypes.Text {
  @serializable
  contentType!: IFeedTypes.ContentType;

  @serializable
  content!: string;
}
