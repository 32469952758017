// https://github.com/wix-private/promote/blob/master/advanced-seo/advanced-seo-utils/src/private/types/ItemTypes.js
export enum GroupSEOItemType {
  GROUPS_FEED = 'GROUPS_FEED',
  GROUPS_MEDIA = 'GROUPS_MEDIA',
  GROUPS_MEMBERS = 'GROUPS_MEMBERS',
  GROUPS_ABOUT = 'GROUPS_ABOUT',
  GROUPS_POST = 'GROUPS_POST',
  GROUPS_COMMENT = 'GROUPS_COMMENT',
  GROUPS_PAGE = 'GROUPS_PAGE',
}

export enum GroupSEOTabType {
  FEED = 'feed',
  MEDIA = 'media',
  MEMBERS = 'members',
  ABOUT = 'about',
}

export interface IMediaSEO {
  videosData: IVideoSEO[];
  imagesData: IImageSEO[];
}

export type ExtractedMediaSEO = IVideoSEO | IImageSEO;

export interface IVideoSEO {
  videoThumbnailUrl?: string;
  videoContentUrl?: string;
  videoName?: string;
}

export interface IImageSEO {
  imageUrl?: string;
  imageHeight?: number;
  imageWidth?: number;
  imageAlt?: number;
  imageCaption?: string;
}
