import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { getGroupFromLocation } from './helpers';
import { BaseWidgetController } from '../../../common/controllers/BaseWidgetController';
import { COMPONENT_ID } from '../../../common/utils/utils';
import {
  CustomUrlSegments,
  getGroupUrl,
  getGroupUrlSegments,
  UrlSegments,
} from '../../../common/controllers/group-url';
import { IErrorEvent } from './errorHandler/IErrorEvent';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { ApiDelegate } from '../../../common/api/services/ApiDelegate';
import { BaseControllerContext } from 'common/controllers';

export abstract class Controller<T> extends BaseWidgetController<
  T & { errorEvent?: IErrorEvent }
> {
  // groupId can be group slug
  private groupId: string;
  protected _api: ApiDelegate;

  protected constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext);
    this.groupId = groupId;
    this._api = ApiDelegate.getInstance(
      new BaseControllerContext(controllerContext),
    );
  }

  getLocation(): ILocation {
    if (this.isEditorMode()) {
      return {
        navigateToSection: () => Promise.resolve(),
        onChange() {},
        to() {},
        baseUrl: '',
        path: ['group', this.groupId, 'discussion'],
        query: {},
        queryParams: {
          add() {},
          remove() {},
        },
        url: '',
      };
    }

    return super.getLocation();
  }

  protected async getGroupId() {
    if (this.groupId) {
      return Promise.resolve(this.groupId);
    }
    try {
      const { groupId } = await getGroupFromLocation(this.controllerContext);
      this.groupId = groupId!;
      return this.groupId;
    } catch (e) {
      console.error('Get Group id: FAIL');
      this.errorLogger.log(e);
    }
  }
  // TODO:class method
  goToGroupList = async () => {
    if (this.isEditorMode()) {
      return this.navigateEditorToGroupList();
    }

    const { relativeUrl: groupListPageRelativeUrl } = await this.getSectionUrl(
      COMPONENT_ID.GROUP_LIST,
    );

    const location = this.getLocation();
    location.to?.(groupListPageRelativeUrl!);
  };

  protected navigateEditorToGroupList() {
    const section = this.getGroupListSection();
    if (typeof window === 'undefined') {
      return this.navigateToSection(section);
    }
    return window.Wix.Utils.navigateToSection(section, console.error);
  }

  protected getGroupListSection() {
    const { compId } = this.controllerConfig;
    const section = {
      sectionId: COMPONENT_ID.GROUP_LIST,
      noTransition: true,
      state: '',
      queryParams: {},
      compId,
    };
    return section;
  }

  async getGroupUrl(seg: CustomUrlSegments) {
    const location = this.getLocation();
    const siteApis = this.getSiteApis();
    return getGroupUrl(location, siteApis, seg);
  }

  getUrlSegments(): Promise<UrlSegments> {
    const location = this.getLocation();
    const siteApis = this.getSiteApis();
    return getGroupUrlSegments(location, siteApis, this.getViewMode());
  }
}
