/**
 * @see ./README.md
 */

export enum ProGalleryTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
}

export interface IProGalleryMetadata {
  type: ProGalleryTypes;
  height: number;
  width: number;
  title: string;
  description: string;
  link?: {
    url: string;
    target?: string;
  };
  backgroundColor?: string;
}

export interface IProGalleryItem {
  itemId: string;
  metaData: IProGalleryMetadata;
}

export class ProGalleryItem implements IProGalleryItem {
  itemId: string;
  metaData: IProGalleryMetadata = {
    backgroundColor: '',
    description: '',
    height: 0,
    link: { url: '' },
    title: '',
    type: undefined as any,
    width: 0,
  };

  constructor(itemId: string, metaData?: IProGalleryMetadata) {
    this.itemId = itemId;
    if (metaData) {
      this.metaData = metaData;
    }
  }

  addDescription(desc: string) {
    this.metaData.description = desc;
  }

  static fromJSON(proItem: IProGalleryItem) {
    return new ProGalleryItem(proItem.itemId, proItem.metaData);
  }
}
