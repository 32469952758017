import { ControllerParams } from '@wix/yoshi-flow-editor';
import { LogLevel } from './Logger';

function getLogLevelFromQueryParams() {
  if (typeof window !== 'undefined' && window.URLSearchParams) {
    const params = new URLSearchParams(location.search);
    return getLogLevel(params.get('debug'));
  }
  return LogLevel.NONE;
}

function getLogLevel(debug: any, trace?: any) {
  if (debug === 'true') {
    return LogLevel.LOG;
  }
  if (debug) {
    return debug;
  }
  if (trace === 'true') {
    return LogLevel.TRACE;
  }
  return LogLevel.NONE;
}

export function debugLog(...messages: any) {
  const mode = getLogLevelFromQueryParams();
  switch (mode) {
    case LogLevel.LOG:
      console.log(...messages);
      break;
    case LogLevel.TRACE:
      console.trace(...messages);
      break;
    default:
  }
}

export function getLogLevelFromControllerConfig(
  controllerConfig: ControllerParams['controllerConfig'],
) {
  let logLevel = LogLevel.NONE;
  try {
    const { debug, trace } = controllerConfig.wixCodeApi.location.query;
    logLevel = getLogLevel(debug, trace);
  } catch (e) {}
  return logLevel;
}
