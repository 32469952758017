import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';

import { date, object, serializable } from 'serializr';

import { SocialIdentity, SocialText } from 'Group/types/feed';
import { IComment, ICommentEntity } from 'Group/types/IFeedItem';

class CommentEntity implements ICommentEntity {
  @serializable(object(SocialText)) text!: SocialText;
}

export class Comment implements IComment {
  @serializable commentId: string;

  @serializable(object(SocialIdentity)) createdBy: SocialIdentity;

  @serializable(date()) createdAt: Date;

  @serializable(object(CommentEntity)) comment: CommentEntity;

  constructor(comment: IFeedTypes.Comment) {
    this.commentId = comment.commentId!;
    this.createdAt = comment.createdAt!;

    this.createdBy = comment.createdBy as SocialIdentity;
    this.comment = comment.comment as ICommentEntity;
  }
}
