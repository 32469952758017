import {
  FollowMemberRequest,
  ListMyMemberFollowingRequest,
  ListMyMemberFollowingResponse,
  UnfollowMemberRequest,
} from '@wix/ambassador-members-v2-follow/types';
import { IMemberFollow } from '../../contexts/MemberFollow/IMemberFollow';
import { Controller } from '../Controller';
import { IMemberFollowApi } from '@wix/social-groups-api/dist/src/services/members/IMemberFollowApi';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class MemberFollow
  extends Controller<IMemberFollow>
  implements IMemberFollowApi
{
  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.onUserLogin(async () => {
      await this.setFollowing();
    });
  }

  private async setFollowing() {
    if (this.isUserLoggedIn()) {
      const following =
        (await this._api.getMembersApi().listMyMemberFollowing({})) || {};
      this.setProps({ following });
    }
  }

  listMyMemberFollowing = async (
    payload?: ListMyMemberFollowingRequest,
  ): Promise<void | ListMyMemberFollowingResponse> => {
    try {
      const following =
        (await this._api
          .getMembersApi()
          .listMyMemberFollowing(payload || {})) || {};
      this.setProps({ following });
    } catch (e) {
      console.log('followMember failed');
    }
  };
  followMember = async (payload: FollowMemberRequest): Promise<void> => {
    try {
      await this._api.getMembersApi().followMember(payload);
      await this.setFollowing();
    } catch (e) {
      console.log('followMember failed');
    }
  };

  unfollowMember = async (payload: UnfollowMemberRequest): Promise<void> => {
    try {
      await this._api.getMembersApi().unfollowMember(payload);
      await this.setFollowing();
    } catch (e) {
      console.log('unfollowMember failed');
    }
  };

  async pageReady(): Promise<any> {
    let following;
    try {
      if (this.isUserLoggedIn()) {
        following =
          (await this._api.getMembersApi().listMyMemberFollowing({})) || {};
      }
    } catch (e) {}
    this.setProps({
      followActions: {
        followMember: this.followMember,
        unfollowMember: this.unfollowMember,
        listMyMemberFollowing: this.listMyMemberFollowing,
      },
      following,
    });
    return Promise.resolve(undefined);
  }
}
